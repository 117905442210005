import { gql, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ScenariosTab from '../components/Tabs/ScenariosTab'
import CoursesTab from '../components/Tabs/CoursesTab'
import { sortOnKey } from '../helpers/format'
import DashboardFilters from '../components/Dashboard/DashboardFilters'
import FilterEmptyState from '../components/UI/Menu/FilterEmptyState'
import { isRoom } from '../helpers/browser'
import DashboardRoomNavs from '../components/Dashboard/DashboardRoomNavs'

export const GET_CLIENTS = gql`
  query getClients {
    clients {
      id
      name
      appTitle
      appDescription
      logoMobileUrl
      logoVrUrl
      primaryColor
      secondaryColor
    }
  }
`
export const GET_SCENARIOS = gql`
  query getScenarios {
    scenarios {
      id
      name
      description
      testing
      highscore
      scoringSystem {
        kind
      }
      voiceEnabled
      locale
      courseIds
      client {
        id
        name
      }
      imageUrl
      createdAt
      lastAttemptAt
    }
  }
`
export const GET_COURSES = gql`
  query getCourses {
    courses {
      id
      name
      description
      createdAt
      client {
        id
        name
        logoMobileUrl
      }
      scenarios {
        id
        name
        imageUrl
        lastAttemptAt
      }
    }
  }
`

const INITIAL_FILTERS = {
  sortBy: 'createdAt',
  show: '',
  client: '',
}

const Dashboard = () => {
  const { t } = useTranslation()
  const getActiveTab = (noCourses) => {
    if (window.location.hash.includes('tests')) return 'tests'
    if (noCourses || window.location.hash.includes('scenarios')) return 'scenarios'
    return 'courses'
  }
  const [activeTab, setActiveTab] = useState(getActiveTab())
  const { data: clientsData, loading: clientsLoading, error: clientsError } = useQuery(GET_CLIENTS)
  const {
    data: scenariosData,
    loading: scenariosLoading,
    error: scenariosError,
  } = useQuery(GET_SCENARIOS)
  const { data: coursesData, loading: coursesLoading, error: coursesError } = useQuery(GET_COURSES)

  const [selectedFilters, _setSelectedFilters] = useState(
    localStorage.getItem('dashboardFilters')
      ? JSON.parse(localStorage.getItem('dashboardFilters'))
      : INITIAL_FILTERS
  )
  const [scnearios, setScenarios] = useState({
    test: [],
    normal: [],
  })
  const [courses, setCourses] = useState()
  const navigate = useNavigate()
  const [roomNavState, setRoomNavState] = useState({
    courses: { count: 0, slide: 0 },
    scenarios: { count: 0, slide: 0 },
  })

  const hasCourses = coursesData?.courses?.length > 0

  const setSelectedFilters = (filters) => {
    localStorage.setItem('dashboardFilters', JSON.stringify(filters))
    _setSelectedFilters(filters)
  }

  const filteredCourses = () => {
    if (!coursesData) return []
    let filtered = [...coursesData.courses]

    if (selectedFilters.client)
      filtered = filtered.filter((course) => course.client.id === selectedFilters.client)

    if (selectedFilters.show)
      filtered = filtered.filter((course) =>
        course.scenarios.some((scenaio) => !scenaio.lastAttemptAt)
      )

    filtered = sortOnKey(filtered, selectedFilters.sortBy, selectedFilters.sortBy === 'createdAt')

    return setCourses(filtered)
  }

  const clients = clientsData ? sortOnKey([...clientsData.clients], 'name') : []

  const getFilteredScenarios = () => {
    if (!scenariosData) return { test: [], normal: [] }

    let filtered = [...scenariosData.scenarios]

    filtered = filtered.filter((scenario) =>
      clients.find((client) => scenario.client.id === client.id)
    )

    if (selectedFilters.client)
      filtered = filtered.filter((scenaio) => scenaio.client.id === selectedFilters.client)
    if (selectedFilters.show) filtered = filtered.filter((scenaio) => !scenaio.lastAttemptAt)

    if (hasCourses)
      filtered = filtered.filter(
        (scenaio) =>
          !coursesData.courses.find((course) => course.scenarios.find((cs) => scenaio.id === cs.id))
      )

    filtered = sortOnKey(filtered, selectedFilters.sortBy, selectedFilters.sortBy === 'createdAt')

    return {
      test: filtered.filter((s) => s.testing),
      normal: filtered.filter((s) => !s.testing),
    }
  }

  useEffect(() => {
    if (coursesData) filteredCourses()
  }, [coursesData, selectedFilters])

  useEffect(() => {
    if (coursesData && coursesData.courses.length === 0) setActiveTab(getActiveTab(true))
  }, [coursesData])

  useEffect(() => {
    if (scenariosData) {
      const filtered = getFilteredScenarios()
      setScenarios(filtered)
    }
  }, [scenariosData, selectedFilters, courses])

  useEffect(() => {
    if (isRoom() && scenariosData && coursesData)
      setRoomNavState({
        courses: { count: coursesData.courses.length, slide: 0 },
        scenarios: { count: getFilteredScenarios().normal.length, slide: 0 },
      })
  }, [scenariosData, coursesData])

  // Error handling
  if (clientsError) throw new Error(clientsError.message)
  if (scenariosError) throw new Error(scenariosError.message)
  if (coursesError) throw new Error(coursesError.message)

  // Loading
  if (clientsLoading || scenariosLoading || coursesLoading) return null

  const tabs = {
    scenarios: {
      title: t('scenarios', 'Scenarios'),
      component:
        scnearios.normal.length || scenariosData.scenarios.length === 0 ? (
          <ScenariosTab
            type="scenarios"
            clients={clients}
            roomSlide={roomNavState[activeTab]?.slide}
            scenarios={scnearios.normal}
          />
        ) : (
          <FilterEmptyState
            title={t('empty_scenarios_state', 'No scenarios match your filter')}
            clickHandler={() => setSelectedFilters(INITIAL_FILTERS)}
          />
        ),
    },
  }

  const generateTabTitles = () => {
    const hasTestScenarios =
      !isRoom() &&
      scenariosData.scenarios.filter((scenario) => scenario.testing === true).length !== 0
    if (hasTestScenarios && scnearios)
      tabs.tests = {
        title: t('tests', 'Tests'),
        component: scnearios.test.length ? (
          <ScenariosTab type="tests" clients={clients} scenarios={scnearios.test} />
        ) : (
          <FilterEmptyState
            title={t('empty_test_scenarios_state', 'No test scenarios match your filter')}
            clickHandler={() => setSelectedFilters(INITIAL_FILTERS)}
          />
        ),
      }

    if (hasCourses)
      tabs.courses = {
        title: t('courses', 'Courses'),
        component: courses?.length ? (
          <CoursesTab courses={courses} roomSlide={roomNavState[activeTab]?.slide} />
        ) : (
          <FilterEmptyState
            title={t('empty_courses_state', 'No courses match your filter')}
            clickHandler={() => setSelectedFilters(INITIAL_FILTERS)}
          />
        ),
      }

    return Object.keys(tabs)
      .sort()
      .map((key) => (
        <button
          type="button"
          data-testid={`${tabs[key].title.toLowerCase()}-tab-title`}
          key={tabs[key].title}
          onClick={() => {
            navigate(`/${key}`)
            setActiveTab(key)
          }}
          className={`transition-none rounded-[40px] py-2 room:py-3 px-6 room:px-8 font-semibold ${
            key === activeTab
              ? 'bg-warp-gray text-white'
              : 'text-gray-400 hover:bg-gray-200 hover:text-gray-600'
          }`}
        >
          {tabs[key].title}
        </button>
      ))
  }

  const renderFilters = () => {
    if (isRoom()) return null
    return (
      <DashboardFilters
        clients={clients}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
    )
  }

  const handleRoomNavs = (side) => {
    setRoomNavState({
      ...roomNavState,
      [activeTab]: {
        ...roomNavState[activeTab],
        slide:
          side === 'left' ? roomNavState[activeTab].slide - 1 : roomNavState[activeTab].slide + 1,
      },
    })
  }

  const renderRoomNavs = () => {
    if (!isRoom()) return null
    return (
      <DashboardRoomNavs activeTab={activeTab} navState={roomNavState} onClick={handleRoomNavs} />
    )
  }

  return (
    <div id="dashboard">
      <div id="tabs-header" className="bg-white">
        <div
          id="tab-titles"
          data-testid="tab-titles"
          className="space-between flex items-center pb-10 flex-wrap"
        >
          <div className="flex flex-auto space-x-4 md:space-x-6 mb-3 lg:mb-0" id="buttons">
            {generateTabTitles()}
          </div>
          {renderFilters()}
          {renderRoomNavs()}
        </div>
      </div>
      <div className="container mx-auto" id="tabs-tabContent">
        {tabs[activeTab]?.component}
      </div>
    </div>
  )
}

export default Dashboard
